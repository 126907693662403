import s from "./AGB.module.css";

function AGB() {
	return (
		<div className={s.container}>
			<h2>Allgemeine Geschäftsbedingungen von Liujun-Onlineshop</h2>
			<strong>Vorwort</strong>
			<p>
				Der nachfolgende Inhalt der allgemeinen Geschäftsbedingeungen bestehen
				aus folgenden Abschnitten:{" "}
			</p>
			<p>
				Die Bestellung über unsere Website www.liujun-shop.de, die App von
				######## oder eine andere Website oder App, auf beziehungsweise in der
				diese allgemeine Geschäftsbedingung enthalten ist (gemeinsam "Plattform"
				genannt) aufgeben, kommt mit der Bestätigung der Annahme Ihrer
				Bestellung ein Kaufvertrag zwischen Ihnen und der ######## mit
				Unternehmensnummer ##### eingetragenem SItz in an der Anschrift
				############# und der Umsatzsteuerindentifikationsnummer zustande, auf
				den die vorliegenden Geschäftsbedingungen und insbesondere die unten
				stehenden Produktkaufbedingungen Anwendung finden.
			</p>
			<p>
				Mit dem Download oder der Installation einer App oder dem Anlegen eines
				Kontos bei ####### schließen Sie einen Vertrag mit dem oben genannten
				Unternehmen von #######. Auf diesen Vertrag finden die vorliegenden
				Geschäftsbedingungen und insbesondere die unten stehenden Bedingungen
				für die Nutzung der Plattform Anwendung. Soweit die Bereitstellung
				personenbezogener Daten zur Nutzung der Plattform oder zum Anlegen des
				Kontos bei ###### nach anwendbarem Recht als Zahlung zu betrachten ist,
				finden darauf außerdem die Kaufbedingungen für digitale Inhalte
				Anwendung.
			</p>
			<strong>Abschnitt 2: Produktkaufbedingungen</strong>
			<p>
				Die vorliegenden Kaufbedingungen finden Anwendung auf sämtliche Angebote
				und Verträge in Bezug auf den Verkauf und die Lieferung von Produkten
				durch uns. Folglich stimmen Sie diesen Kaufbedingungen zu, wenn Sie (i)
				ein Produkt über die Plattform bestellen, (ii) ein Produkt über eine
				unmittelbar mit der Plattform verknüpfte Webseite bestellen oder (iii)
				Sie ein Angebot über ein Produkt von uns annehmen. Abweichungen von
				diesen Kaufbedingungen bedürfen unserer schriftlichen Zustimmung.
			</p>
			<p>
				Wir führen verschiedene Arten von Produkte, unterannderem vollständig
				eigenentworfene Produkte, aber auch Produkte die durch Ihnen
				personalisiert wurden. Nachfolgend benennen wir diese zusammengefasst
				als "Produkte". Produkte, die in limitierter Auflage bzw. Produktion
				vorhanden sind, unterliegen besondere Beschränkungen hinsichtlich des
				Erwerbs. Unser Shop behält sich vor, nur eine gewisse Anzahl an
				Produkten in diesem Fall pro Kunde für den Verkauf anzubieten. Wir
				bemühen uns, den Erwartungen des Kunden gerecht zu werden und versuchen
				die Verfügbarkeit anzupassen. Sämtliche auf der Plattform angezeigten
				Produkte werden vorbehaltlich ihrer Verfügbarkeit angeboten. Das
				bedeutet, dass ein auf der Plattform angezeigtes Produkt möglicherweise
				nicht mehr zum Kauf verfügbar ist, obwohl wir uns darum bemühen, dass
				die Plattform die Lagerverfügbarkeit widerspiegelt. Geringfügige
				Abweichungen können auf den Bildern unseres Shops auftauchen. Dies liegt
				an den Anzeigetechnologien die wir für unseren Onlineshop nutzen. Aus
				Schreibfehlern, Produktbeschreibungen oder offensichtlichen Fehlern auf
				der Plattform können keinerlei Rechte abgeleitet werden.
			</p>
			<p>
				Um unsere Produkte erwerben zu können, müssen Sie mindestens 16 Jahre
				alt sein. Sie garantieren, dass die von uns erfragten Angaben
				vollständig und korrekt sind.
			</p>
			<p>
				Alle von Ihnen getätigten Bestellungen bedürfen unserer Zustimmung bzw.
				Bestätigung. Wir sind außerdem jederzeit berechtigt Bestellungen ohne
				Angabe von Gründen und Haftungsübernahme abzulehnen. Eine Bestellung
				gilt erst dann als bestätigt, wenn wir Ihnen innerhalb 14 Tagen eine
				schriftliche Bestätigung in den meisten Fällen per Mail mitteilen.{" "}
			</p>
			<p>
				Ihre Bestellungen können unter anderem aus folgenden und nach unserem
				Ermessen abgelehnt werden:
			</p>
			<ul>
				<li>
					wenn (ein) Produkt(e) auf der Plattform angezeigt wird/werden, aber
					nicht mehr verfügbar ist/sind
				</li>
				<li>wenn wir keine Berechtigung für Ihre Zahlung erhalten können</li>
				<li>wenn für ein Produkt Versandbeschränkungen gelten</li>
				<li>
					wenn Produktanzeigen auf der Plattform einen (offensichtlichen) Fehler
					aufweisen, z. B. wenn nicht der richtige Preis ausgewiesen ist oder
					die Produkte anderweitig falsch beschrieben sind oder falsch angezeigt
					werden
				</li>
				<li>
					falls wir Ihre Bestellung aus technischen Gründen nicht bearbeiten
					können
				</li>
				<li>
					falls wir wissen oder begründeterweise annehmen, dass eine Bestellung
					mit Unterstützung oder Beteiligung von Software, Robots, Webcrawlern,
					Webspidern oder anderweitigen automatisierten Mitteln oder Geräten
					irgendeiner Art aufgegeben wurde
				</li>
			</ul>
			<p>
				Für den Fall, dass wir Ihre Bestellung (ganz oder teilweise) nicht
				annehmen, sind wir berechtigt, Ihre Bestellung (ganz oder teilweise) zu
				stornieren, ohne dadurch Ihnen oder Dritten gegenüber zu haften. Nach
				einer Stornierung erstatten wir Ihnen den von Ihnen an uns gezahlten
				Betrag für die stornierte Bestellung (bzw. den stornierten Teil davon)
				selbstverständlich zurück.
			</p>
			<p>Letzte Aktualisierung: 22. März 2022</p>
		</div>
	);
}

export default AGB;
