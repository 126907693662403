function ShoppingBagIcon(props) {
	if (props.add)
		return (
			<svg
				className="h-6 w-6"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor">
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M16 11V7a4 4 0 0 0-8 0v4M5 9h14l1 12H4L5 9z"
				/>
				<path
					style={{
						fill: "#000",
						strokeWidth: 0.627938,
					}}
					d="M11.314 11.314h1.372v7.372h-1.372z"
				/>
				<path
					style={{
						fill: "#000",
						strokeWidth: 0.756528,
					}}
					d="M8.378 14.378h7.243v1.243H8.378z"
				/>
			</svg>
		);
	if (props.item_count === 0)
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="h-6 w-6"
				fill="none"
				viewBox="0 0 50 24"
				stroke="currentColor">
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1.5}
					d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
				/>
			</svg>
		);
	else
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				className="h-6 w-6"
				fill="none"
				viewBox="0 0 50 24"
				stroke="currentColor">
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1.5}
					d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
				/>
				<g id="badge">
					<rect x="24" y="6" height="16" width="18" strokeWidth="1.5" rx="5" />
					<text
						x="33"
						y="15"
						dominantBaseline="middle"
						textAnchor="middle"
						strokeWidth="0.7"
						fontSize="10">
						{props.item_count}
					</text>
				</g>
			</svg>
		);
}

export default ShoppingBagIcon;
