import s from "./ProductEditor.module.css";

import ShoppingBagIcon from "../icons/ShoppingBagIcon";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

function saveProduct(setSaved) {
  setSaved(true);
}

function newColor() {
  return ("#" + Math.floor(Math.random()*16777215).toString(16));
}

function ProductEditor() {
	const params = useParams();
	useTranslation();

  const [colors, setColors] = useState(["black", "white", "navy"]);

  const [saved, setSaved] = useState(false)

	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({});

	//useEffect(() => {
	//	fetch(`/api/items/${params.id}`)
	//		.then((res) => res.json())
	//		.then((data) => {
	//			setData(data);
	//			setLoading(false);
	//		});
	//}, [params.id]);

	return (
    <>
    {saved ?  <p className={s.saved}>Product saved</p> : ''}
		<div className={s.container}>
			<div className={s.image_container}>
				<img
					className={s.image}
					src="/pix/placeholder.png"
					alt="Alternative text missing"
				/>
			</div>
			<div className={s.info_container}>
        <input className={s.itemname} placeholder="Item Name" /><br />
        <textarea className={s.desc} placeholder="Description"></textarea><br />
        <p className={s.price}> <input placeholder="Price"></input><span>€</span></p>
				<div className={s.options}>
					<div className={s.colors}>
						<span className={s.option_name}>
							<Trans i18nKey="prod.col">Farbe</Trans>
						</span>
            {colors.map((color) => 
              <><div
                className={s.color_circle}
                style={{ backgroundColor: color }}></div>
              </>
            )}
            <button onClick={() => {
              setColors(colors.concat([newColor()]));
            }}
              className={s.color_circle}
              style={{backgroundColor: "white" }}>+</button>
					</div>
					<div className={s.sizes}>
						<span className={s.option_name}>
							<Trans i18nKey="prod.size">Größe</Trans>
						</span>
						<select className={s.size_options}>
							<option>XS</option>
							<option>S</option>
							<option>M</option>
							<option>L</option>
							<option>XL</option>
						</select>
					</div>
				</div>
				<div className={s.cart_area}>
					<button className={s.saveButton} onClick={() => saveProduct(setSaved)}>
						<Trans i18nKey="prod.save">
							Speichern
							<ShoppingBagIcon add />
						</Trans>
					</button>
				</div>
			</div>
		</div>
	</>);
}

export default ProductEditor;
