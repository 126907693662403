function InstagramLogo() {
	return (
		<svg width={35} height={35} fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.5 35C27.165 35 35 27.165 35 17.5S27.165 0 17.5 0 0 7.835 0 17.5 7.835 35 17.5 35Zm4.243-26.047c-1.107-.05-1.44-.061-4.243-.061-2.804 0-3.136.01-4.243.061-1.024.047-1.58.218-1.95.362-.49.19-.84.418-1.207.785a3.253 3.253 0 0 0-.785 1.207c-.144.37-.315.926-.362 1.95-.05 1.107-.061 1.44-.061 4.243 0 2.804.01 3.136.061 4.243.047 1.024.218 1.58.362 1.95.19.49.418.84.785 1.207.367.367.717.595 1.207.785.37.144.926.315 1.95.362 1.107.05 1.44.061 4.243.061 2.804 0 3.136-.01 4.243-.061 1.024-.047 1.58-.218 1.95-.362.49-.19.84-.418 1.207-.785.367-.367.595-.717.785-1.207.144-.37.315-.926.362-1.95.05-1.107.061-1.44.061-4.243 0-2.804-.01-3.136-.061-4.243-.047-1.024-.218-1.58-.362-1.95-.19-.49-.418-.84-.785-1.207a3.253 3.253 0 0 0-1.207-.785c-.37-.144-.926-.315-1.95-.362Zm-8.572-1.89C14.29 7.013 14.648 7 17.5 7c2.852 0 3.21.012 4.33.063 1.117.051 1.88.229 2.548.488.69.269 1.276.628 1.86 1.211.583.584.942 1.17 1.21 1.86.26.668.438 1.431.489 2.549.05 1.12.063 1.477.063 4.329 0 2.852-.012 3.21-.063 4.33-.051 1.117-.229 1.88-.488 2.548a5.149 5.149 0 0 1-1.211 1.86 5.149 5.149 0 0 1-1.86 1.21c-.668.26-1.431.438-2.549.489-1.12.05-1.477.063-4.329.063-2.852 0-3.21-.012-4.33-.063-1.117-.051-1.88-.229-2.548-.488a5.148 5.148 0 0 1-1.86-1.211 5.147 5.147 0 0 1-1.21-1.86c-.26-.668-.438-1.431-.489-2.549C7.013 20.71 7 20.352 7 17.5c0-2.852.012-3.21.063-4.33.051-1.117.229-1.88.488-2.548a5.147 5.147 0 0 1 1.211-1.86 5.147 5.147 0 0 1 1.86-1.21c.668-.26 1.431-.438 2.549-.489Zm4.329 5.045a5.392 5.392 0 1 0 0 10.784 5.392 5.392 0 0 0 0-10.784Zm0 8.892a3.5 3.5 0 1 1 0-7 3.5 3.5 0 0 1 0 7Zm6.865-9.105a1.26 1.26 0 1 1-2.52 0 1.26 1.26 0 0 1 2.52 0Z"
				fill="#000"
			/>
		</svg>
	);
}

export default InstagramLogo;
