// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Login_page__yXPXa {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n}\n\n.Login_form_container__crcji {\n\twidth: 25%;\n\tpadding: 20px;\n}\n\n.Login_title__j9SaB {\n\tfont-size: 24px;\n\tfont-weight: 300;\n}\n\n.Login_form__5n2LA {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.Login_input_field__6SDgJ {\n\tflex: 1 1;\n\tmin-width: 40%;\n\tmargin: 10px 0;\n\tpadding: 10px;\n}\n\n.Login_agreement__uCquZ {\n\tmargin: 20px 0;\n\tfont-size: 12px;\n}\n\n.Login_btn__q8dTz {\n\tmargin-top: 10px;\n\twidth: 40%;\n\tpadding: 15px;\n\tborder: 2px solid var(--accent-base);\n\tbackground-color: white;\n\tcursor: pointer;\n\tfont-weight: 500;\n\tfont-family: inherit;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Login.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,uBAAuB;AACxB;;AAEA;CACC,UAAU;CACV,aAAa;AACd;;AAEA;CACC,eAAe;CACf,gBAAgB;AACjB;;AAEA;CACC,aAAa;CACb,sBAAsB;AACvB;;AAEA;CACC,SAAO;CACP,cAAc;CACd,cAAc;CACd,aAAa;AACd;;AAEA;CACC,cAAc;CACd,eAAe;AAChB;;AAEA;CACC,gBAAgB;CAChB,UAAU;CACV,aAAa;CACb,oCAAoC;CACpC,uBAAuB;CACvB,eAAe;CACf,gBAAgB;CAChB,oBAAoB;AACrB","sourcesContent":[".page {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n}\n\n.form_container {\n\twidth: 25%;\n\tpadding: 20px;\n}\n\n.title {\n\tfont-size: 24px;\n\tfont-weight: 300;\n}\n\n.form {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.input_field {\n\tflex: 1;\n\tmin-width: 40%;\n\tmargin: 10px 0;\n\tpadding: 10px;\n}\n\n.agreement {\n\tmargin: 20px 0;\n\tfont-size: 12px;\n}\n\n.btn {\n\tmargin-top: 10px;\n\twidth: 40%;\n\tpadding: 15px;\n\tborder: 2px solid var(--accent-base);\n\tbackground-color: white;\n\tcursor: pointer;\n\tfont-weight: 500;\n\tfont-family: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "Login_page__yXPXa",
	"form_container": "Login_form_container__crcji",
	"title": "Login_title__j9SaB",
	"form": "Login_form__5n2LA",
	"input_field": "Login_input_field__6SDgJ",
	"agreement": "Login_agreement__uCquZ",
	"btn": "Login_btn__q8dTz"
};
export default ___CSS_LOADER_EXPORT___;
