import { Trans, useTranslation } from "react-i18next";
import s from "./Register.module.css";
import { useState } from "react";

function register(e, setErr) {
  e.preventDefault();
  let data = {
    title: 'Dr.',
    firstname: e.currentTarget.elements[0].value,
    lastname: e.currentTarget.elements[1].value,
    email: e.currentTarget.elements[3].value,
    password: e.currentTarget.elements[4].value,
  };

  fetch('/api/register', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  }).then((res) => res.json())
  .then((data) => {
    setErr({loading: false, e: data.errors ?? []});
  });
}

function Register() {
  const [errors, setErrors] = useState({loading: true, e: []});

	useTranslation();
	return (
		<div className={s.page}>
			<div className={s.form_container}>
				<h1 className={s.title}>
					<Trans i18nKey="reg.header">Neues Konto anlegen</Trans>
				</h1>
        {errors.loading ? '' :
          errors.e.length ?
          <div className={s.regErrors}>
            {errors.e.map(e => (
              <><p>{e}</p></>
            ))}
          </div> :
          <div className={s.regSuccess}>
            <Trans i18nKey="reg.success">Erfolgreich registriert</Trans>
          </div>
        }
				<form className={s.form} onSubmit={(e) => {register(e, setErrors)}}>
					<input className={s.input_field} placeholder="Vorname" />
					<input className={s.input_field} placeholder="Nachname" />
					<input className={s.input_field} placeholder="Nutzername" />
					<input className={s.input_field} type="email" placeholder="E-Mail" />
					<input
						className={s.input_field}
						type="password"
						placeholder="Passwort"
					/>
					<input
						className={s.input_field}
						type="password"
						placeholder="Passwort bestätigen"
					/>
					<span className={s.agreement}>
						<Trans i18nKey="reg.hint">
							Mit Ihrer Anmeldung erklären Sie sich mit unseren Allgemeinen
							Geschäftsbedingungen einverstanden.
						</Trans>
					</span>
					<button className={s.btn} type="submit">
						<Trans i18nKey="reg.btn">Registrieren</Trans>
					</button>
				</form>
			</div>
		</div>
	);
}

export default Register;
