// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Button_btn__5IXYL {\n\tfont-size: 20px;\n\tfont-weight: 600;\n\tborder: none;\n\tpadding: 10px;\n\tbackground-color: whitesmoke;\n\tcursor: pointer;\n\tfont-family: \"Nunito Sans\", Arial, Helvetica, sans-serif;\n}\n", "",{"version":3,"sources":["webpack://./src/reusables/Button.module.css"],"names":[],"mappings":"AAAA;CACC,eAAe;CACf,gBAAgB;CAChB,YAAY;CACZ,aAAa;CACb,4BAA4B;CAC5B,eAAe;CACf,wDAAwD;AACzD","sourcesContent":[".btn {\n\tfont-size: 20px;\n\tfont-weight: 600;\n\tborder: none;\n\tpadding: 10px;\n\tbackground-color: whitesmoke;\n\tcursor: pointer;\n\tfont-family: \"Nunito Sans\", Arial, Helvetica, sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "Button_btn__5IXYL"
};
export default ___CSS_LOADER_EXPORT___;
