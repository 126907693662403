import { useTranslation, Trans } from "react-i18next";
import { useContext } from "react";
import { Link, Navigate } from "react-router-dom";
import s from "./Login.module.css";
import {UserContext} from '../Liujun.js';

function login(e, context) {
  e.preventDefault();
  let data = {
    email: e.currentTarget.elements[0].value,
    password: e.currentTarget.elements[1].value,
    stay: true,
  };
  fetch('/api/login', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(data)
  }).then((res) => {
    if(res.status===204) {
      context.set({login: true});
      console.log("logged in");
    }
    else
      console.log("login unsuccessful");
  });
}

function Login() {
	useTranslation();

  const user = useContext(UserContext);

	return (
		<div className={s.page}>
			<div className={s.form_container}>
        {(!user.loading && user.login) ?
          <Navigate to="/profile" /> : <>
            <h1 className={s.title}>
              <Trans i18nKey="log.header">Einloggen</Trans>
            </h1>
            <form className={s.form} onSubmit={(e) => login(e, user)}>
              <input className={s.input_field} placeholder="Email" />
              <input
                className={s.input_field}
                type="password"
                placeholder="Passwort"
              />
              <button className={s.btn}>
                <Trans i18nKey="log.btn">Einloggen</Trans>
              </button>
            </form>
            <br />
            <Link to="/">
              <Trans i18nKey="log.pass">Passwort vergessen?</Trans>
            </Link>{" "}
            <br /> <br />
            <Link to="/register">
              <Trans i18nKey="log.noacc">Noch kein Konto? Hier eins anlegen!</Trans>
            </Link>
          </>
        }
			</div>
		</div>
	);
}

export default Login;
