import AnnouncementBanner from "./layout/AnnouncementBanner";
import Navbar from "./layout/Navbar";
import Footer from "./layout/Footer";

import s from "./Layout.module.css";

function Layout(props) {
	return (
		<div className={s.container}>
			<AnnouncementBanner />
			<Navbar />
			<div className={s.content}>{props.children}</div>
			<Footer />
		</div>
	);
}

export default Layout;
