import Minus from "../../icons/Minus";
import Plus from "../../icons/Plus";
import s from "./BagProduct.module.css";

function BagProduct(props) {
	return (
		<div className={s.product}>
			<div className={s.left_col}>
				<img className={s.pic} src={props.img} alt="Alternative text missing" />
				<div className={s.details}>
					<span>{props.name}</span>
					<span>{props.size ? props.size : ""}</span>
					<span>{props.color}</span>
				</div>
			</div>
			<div className={s.right_col}>
				<div className={s.quantity}>
					<Minus />
					<div className={s.number}>{props.number}</div>
					<Plus />
				</div>
				<span className={s.price}>{props.price}€</span>
			</div>
		</div>
	);
}

export default BagProduct;
