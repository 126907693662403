import CategoryItem from "./categories/CategoryItem";

import s from "./Categories.module.css";

const cats = [
	{
		id: 1,
		img: "https://st2.depositphotos.com/2939989/9208/i/950/depositphotos_92084594-stock-photo-female-clothes-collage-women-apparel.jpg",
		imgalt: "some desc",
		title: "Kleidung",
	},
	{
		id: 2,
		img: "https://previews.123rf.com/images/fabrikacrimea/fabrikacrimea1709/fabrikacrimea170911597/86088574-collage-der-taschen-der-frauen-getrennt-auf-wei%C3%9F.jpg",
		imgalt: "some desc",
		title: "Taschen",
	},
	{
		id: 3,
		img: "https://st2.depositphotos.com/7595566/11227/i/950/depositphotos_112276438-stock-photo-woman-summer-accessories-collage.jpg",
		imgalt: "some desc",
		title: "Accessories",
	},
];

function Categories(props) {
	return (
		<div className={s.container}>
			{cats.map((cat) => {
				return (
					<CategoryItem key={cat.id} imglink={cat.img} title={cat.title} />
				);
			})}
		</div>
	);
}

export default Categories;
