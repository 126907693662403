import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const EN = require("./locales/en/translations.json");
const DE = require("./locales/de/translations.json");

const resources = {
	en: { translation: EN },
	de: { translation: DE },
};

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		fallbackLng: "de",
		debug: false,
		detection: { order: ["queryString", "cookie"], cache: ["cookie"] },
		interpolation: { escapeValue: false },
	});

export default i18n;
