import s from "./SingleProduct.module.css";

import Plus from "../icons/Plus";
import Minus from "../icons/Minus";
import ShoppingBagIcon from "../icons/ShoppingBagIcon";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Spinner from "../reusables/Spinner";
import { Trans, useTranslation } from "react-i18next";

function SingleProduct() {
	const params = useParams();
	useTranslation();

	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({});

	useEffect(() => {
		fetch(`/api/items/${params.id}`)
			.then((res) => res.json())
			.then((data) => {
				setData(data);
				setLoading(false);
			});
	}, [params.id]);

	if (loading) return <Spinner />;

	return (
		<div className={s.container}>
			<div className={s.image_container}>
				<img
					className={s.image}
					src={data.image}
					alt="Alternative text missing"
				/>
			</div>
			<div className={s.info_container}>
				<h1 className={s.itemname}>{data.name}</h1>
				<p className={s.desc}>{data.description}</p>
				<span className={s.price}>{data.price}€</span>
				<div className={s.options}>
					<div className={s.colors}>
						<span className={s.option_name}>
							<Trans i18nKey="prod.col">Farbe</Trans>
						</span>
						<div
							className={s.color_circle}
							style={{ backgroundColor: "black" }}></div>
						<div
							className={s.color_circle}
							style={{ backgroundColor: "white" }}></div>
						<div
							className={s.color_circle}
							style={{ backgroundColor: "navy" }}></div>
					</div>
					<div className={s.sizes}>
						<span className={s.option_name}>
							<Trans i18nKey="prod.size">Größe</Trans>
						</span>
						<select className={s.size_options}>
							<option>XS</option>
							<option>S</option>
							<option>M</option>
							<option>L</option>
							<option>XL</option>
						</select>
					</div>
				</div>
				<div className={s.cart_area}>
					<div className={s.amount}>
						<Minus />
						<span className={s.amount_input}>1</span>
						<Plus />
					</div>
					<button className={s.add_button}>
						<Trans i18nKey="prod.bag">
							In die Einkaufstasche
							<ShoppingBagIcon add />
						</Trans>
					</button>
				</div>
			</div>
		</div>
	);
}

export default SingleProduct;
