function FacebookLogo() {
	return (
		<svg width={35} height={35} fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.5 0C27.165 0 35 7.883 35 17.607 35 26.395 28.6 33.679 20.234 35V22.697h4.078l.776-5.09h-4.854v-3.303c0-.174.011-.347.034-.518.16-1.192.916-2.231 2.818-2.231h2.207V7.222s-.125-.022-.343-.054c-.653-.097-2.138-.29-3.574-.29-3.998 0-6.61 2.437-6.61 6.85v3.879h-4.444v5.09h4.444V35C6.399 33.68 0 26.395 0 17.607 0 7.883 7.835 0 17.5 0Z"
				fill="#000"
			/>
		</svg>
	);
}

export default FacebookLogo;
