// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CategoryItem_container__PuhvM {\n\tflex: 1 1;\n\tmargin: 3px;\n\theight: 70vh;\n\tposition: relative;\n}\n\n.CategoryItem_image__lWDPe {\n\twidth: 100%;\n\theight: 100%;\n\tobject-fit: cover;\n}\n\n.CategoryItem_info__mTuVB {\n\twidth: 100%;\n\theight: 100%;\n\tposition: absolute;\n\ttop: 0;\n\tleft: 0;\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tjustify-content: center;\n}\n\n.CategoryItem_title__TCbiU {\n\tcolor: var(--accent-fg);\n\tmargin-bottom: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/home/categories/CategoryItem.module.css"],"names":[],"mappings":"AAAA;CACC,SAAO;CACP,WAAW;CACX,YAAY;CACZ,kBAAkB;AACnB;;AAEA;CACC,WAAW;CACX,YAAY;CACZ,iBAAiB;AAClB;;AAEA;CACC,WAAW;CACX,YAAY;CACZ,kBAAkB;CAClB,MAAM;CACN,OAAO;CACP,aAAa;CACb,sBAAsB;CACtB,mBAAmB;CACnB,uBAAuB;AACxB;;AAEA;CACC,uBAAuB;CACvB,mBAAmB;AACpB","sourcesContent":[".container {\n\tflex: 1;\n\tmargin: 3px;\n\theight: 70vh;\n\tposition: relative;\n}\n\n.image {\n\twidth: 100%;\n\theight: 100%;\n\tobject-fit: cover;\n}\n\n.info {\n\twidth: 100%;\n\theight: 100%;\n\tposition: absolute;\n\ttop: 0;\n\tleft: 0;\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tjustify-content: center;\n}\n\n.title {\n\tcolor: var(--accent-fg);\n\tmargin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CategoryItem_container__PuhvM",
	"image": "CategoryItem_image__lWDPe",
	"info": "CategoryItem_info__mTuVB",
	"title": "CategoryItem_title__TCbiU"
};
export default ___CSS_LOADER_EXPORT___;
