import Slider from "./home/Slider";
import Categories from "./home/Categories";
import PopularProducts from "./home/PopularProducts";

import { Trans, useTranslation } from "react-i18next";

import s from "./Home.module.css";
import { useEffect, useState } from "react";
import Spinner from "../reusables/Spinner";

function Home() {
	useTranslation();

	const [loading, setLoading] = useState(true);
	const [favclothes, setFavclothes] = useState([]);
	const [favbags, setFavbags] = useState([]);
	const [favacc, setFavacc] = useState([]);

	useEffect(() => {
		Promise.all([
			fetch(
				"https://fakestoreapi.com/products/category/women's%20clothing?limit=5"
			),
			fetch(
				"https://fakestoreapi.com/products/category/men's%20clothing?limit=5"
			),
			fetch("https://fakestoreapi.com/products/category/jewelery?limit=5"),
		])
			.then(([res1, res2, res3]) =>
				Promise.all([res1.json(), res2.json(), res3.json()])
			)
			.then(([data1, data2, data3]) => {
				setFavclothes(data1);
				setFavbags(data2);
				setFavacc(data3);
				setLoading(false);
			});
	}, []);

	if (loading) return <Spinner />;

	return (
		<div>
			<h3 className={s.headers}>
				<Trans i18nKey="home.news">Neuigkeiten</Trans>
			</h3>
			<Slider />
			<h3 className={s.headers}>
				<Trans i18nKey="home.byCat">Nach Kategorie shoppen</Trans>
			</h3>
			<Categories />
			<h3 className={s.headers}>
				<Trans i18nKey="home.favClothes">Unsere Lieblingskleidungsstücke</Trans>
			</h3>
			<PopularProducts products={favclothes} />
			<h3 className={s.headers}>
				<Trans i18nKey="home.favBags">Unsere Lieblingstaschen</Trans>
			</h3>
			<PopularProducts products={favbags} />
			<h3 className={s.headers}>
				<Trans i18nKey="home.favAcc">Unsere Lieblings-Accessories</Trans>
			</h3>
			<PopularProducts products={favacc} />
		</div>
	);
}

export default Home;
