import ProductCard from "../../reusables/ProductCard";

import s from "./PopularProducts.module.css";

function PopularProducts(props) {
	return (
		<div className={s.container}>
			{props.products.map((product) => (
				<ProductCard
					key={product.id}
					id={product.id}
					img={product.image}
					price={product.price}
				/>
			))}
		</div>
	);
}

export default PopularProducts;
