function VisaIcon() {
	return (
		<svg
			data-name="Payment Icons"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 38 38">
			<title>{"visa-fill"}</title>
			<rect y={7} width={38} height={24} rx={5} />
			<path
				d="m15.76 15.56-2.87 6.89H11L9.61 17a.75.75 0 0 0-.42-.61 7.69 7.69 0 0 0-1.74-.59v-.2h3a.84.84 0 0 1 .82.71l.74 4 1.84-4.69Zm7.33 4.64c0-1.81-2.5-1.91-2.48-2.73 0-.24.24-.51.75-.57a3.32 3.32 0 0 1 1.75.3l.31-1.46a4.93 4.93 0 0 0-1.66-.3c-1.75 0-3 .93-3 2.28 0 1 .88 1.54 1.55 1.87s.92.56.92.86c0 .46-.55.66-1.06.67a3.66 3.66 0 0 1-1.82-.43L18 22.2a5.41 5.41 0 0 0 2 .36c1.86 0 3.07-.92 3.08-2.36m4.62 2.25h1.63l-1.42-6.89h-1.5a.82.82 0 0 0-.76.51L23 22.45h1.86l.36-1h2.27Zm-2-2.44.94-2.58.56 2.57Zm-7.44-4.45-1.46 6.89h-1.74l1.46-6.89Z"
				style={{
					fill: "#fff",
				}}
			/>
		</svg>
	);
}

export default VisaIcon;
