// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Layout_container__I\\+R4e {\n\tdisplay: flex;\n\tflex-direction: column;\n\tmin-height: 100vh;\n}\n.Layout_content__ftvFA {\n\tflex: 1 1;\n}\n", "",{"version":3,"sources":["webpack://./src/Layout.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;CACtB,iBAAiB;AAClB;AACA;CACC,SAAO;AACR","sourcesContent":[".container {\n\tdisplay: flex;\n\tflex-direction: column;\n\tmin-height: 100vh;\n}\n.content {\n\tflex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Layout_container__I+R4e",
	"content": "Layout_content__ftvFA"
};
export default ___CSS_LOADER_EXPORT___;
