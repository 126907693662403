import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import ChevronIcon from "../../icons/ChevronIcon";
import Button from "../../reusables/Button";

import s from "./Slider.module.css";

const slides = [
	{
		id: 0,
		img: "./pix/cover1.jpeg",
		title: "Herbst/Winter Saison 2021/22",
		desc: "Die neue Kollektion ist eingetroffen!",
		imgalt: "",
	},
	{
		id: 1,
		img: "./pix/cover2.jpeg",
		title: "Neue Favoriten",
		desc: "Lernen Sie die neuen Mitglieder unserer permanenten Kollektion kennen",
		imgalt: "",
	},
	{
		id: 2,
		img: "./pix/cover3.jpeg",
		title: "Ferien-Sale!",
		desc: "Frohe Weihnachten! Hier geht's zu den aktuellen Deals",
		imgalt: "",
	},
];

function Slider() {
	useTranslation();
	const [currentSlide, setCurrentSlide] = useState(0);

	function handleSwitchingSlides(dir) {
		if (dir === "l")
			setCurrentSlide(currentSlide > 0 ? currentSlide - 1 : slides.length - 1);
		else if (dir === "r")
			setCurrentSlide(currentSlide < 2 ? currentSlide + 1 : 0);
	}

	return (
		<div className={s.container}>
			<div
				className={`${s.arrow} ${s.arrow_left}`}
				onClick={() => handleSwitchingSlides("l")}>
				<ChevronIcon dir="l" />
			</div>
			<div
				className={s.wrapper}
				style={{ transform: "translate(" + currentSlide * -100 + "vw)" }}>
				{slides.map((slide) => (
					<div className={s.slide} key={slide.id}>
						<div className={s.info}>
							<h3 className={s.slide_title}>{slide.title}</h3>
							<p className={s.slide_desc}>{slide.desc}</p>
							<form className={s.form} action="/all">
								<Button>
										<Trans i18nKey="slider-btn">Jetzt Shoppen</Trans>
								</Button>
              </form>
						</div>
						<img className={s.img} src={slide.img} alt={slide.imgalt} />
					</div>
				))}
			</div>
			<div
				className={`${s.arrow} ${s.arrow_right}`}
				onClick={() => handleSwitchingSlides("r")}>
				<ChevronIcon dir="r" />
			</div>
		</div>
	);
}

export default Slider;
