import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";
import s from "./Profile.module.css";
import React, { useEffect, useState } from "react";
import Spinner from '../reusables/Spinner.js';

function viewUserData(loading, data) {
  if(loading)
    return <Spinner />;
  return (<>
    <div className={s.dataName}>
      <Trans i18nKey="pro.first">First Name</Trans>
    </div>
    <div className={s.dataElement}>
      {data.firstname}
    </div>
    <br />
    <div className={s.dataName}>
      <Trans i18nKey="pro.last">Last Name</Trans>
    </div>
    <div className={s.dataElement}>
      {data.lastname}
    </div>
    <br />
    <div className={s.dataName}>
      <Trans i18nKey="pro.email">Email</Trans>
    </div>
    <div className={s.dataElement}>
      {data.email}
    </div>
    <br />
    <div className={s.dataName}>
      <Trans i18nKey="pro.admin">Admin</Trans>
    </div>
    <div className={s.dataElement}>
      {data.admin ? "Yes" : "No"}
    </div>
    <br />
  </>)
}

function Profile() {
  useTranslation();
  const [user, setUser] = useState();
	const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    fetch("/api/user/me")
      .then((res) => res.json())
      .then((data) => {
        setUser(data);
        setLoading(false);
    });
  }, []);

  return (
    <div className={s.page}>
      <div className={s.form_container}>
        <h1 className={s.title}>
          <Trans i18nKey="pro.header">Profile</Trans>
        </h1>
        <div className={s.viewContainer}>
          {viewUserData(loading, user)}
        </div>
      </div>
    </div>
  );
}

export default Profile;

