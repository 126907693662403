// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AnnouncementBanner_container__4pqa1 {\n\theight: 35px;\n\tbackground-color: whitesmoke;\n\tcolor: var(--accent-base);\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tfont-size: 20px;\n\tfont-weight: 600;\n}\n\n.AnnouncementBanner_visible__4Uuqs {\n\tposition: absolute;\n\tvisibility: visible;\n\topacity: 1;\n\ttransition: opacity 0.5s linear;\n}\n\n.AnnouncementBanner_hidden__QH9Ou {\n\tposition: absolute;\n\tvisibility: hidden;\n\topacity: 0;\n\ttransition: visibility 0s 0.5s, opacity 0.5s linear;\n}\n", "",{"version":3,"sources":["webpack://./src/layout/AnnouncementBanner.module.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,4BAA4B;CAC5B,yBAAyB;CACzB,aAAa;CACb,mBAAmB;CACnB,uBAAuB;CACvB,eAAe;CACf,gBAAgB;AACjB;;AAEA;CACC,kBAAkB;CAClB,mBAAmB;CACnB,UAAU;CACV,+BAA+B;AAChC;;AAEA;CACC,kBAAkB;CAClB,kBAAkB;CAClB,UAAU;CACV,mDAAmD;AACpD","sourcesContent":[".container {\n\theight: 35px;\n\tbackground-color: whitesmoke;\n\tcolor: var(--accent-base);\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tfont-size: 20px;\n\tfont-weight: 600;\n}\n\n.visible {\n\tposition: absolute;\n\tvisibility: visible;\n\topacity: 1;\n\ttransition: opacity 0.5s linear;\n}\n\n.hidden {\n\tposition: absolute;\n\tvisibility: hidden;\n\topacity: 0;\n\ttransition: visibility 0s 0.5s, opacity 0.5s linear;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AnnouncementBanner_container__4pqa1",
	"visible": "AnnouncementBanner_visible__4Uuqs",
	"hidden": "AnnouncementBanner_hidden__QH9Ou"
};
export default ___CSS_LOADER_EXPORT___;
