// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Categories_container__MlNIr {\n\tdisplay: flex;\n\tpadding: 10px;\n\tjustify-content: space-between;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/home/Categories.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,aAAa;CACb,8BAA8B;AAC/B","sourcesContent":[".container {\n\tdisplay: flex;\n\tpadding: 10px;\n\tjustify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Categories_container__MlNIr"
};
export default ___CSS_LOADER_EXPORT___;
