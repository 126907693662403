import { useState, useEffect } from "react";
import s from "./AnnouncementBanner.module.css";

const content = {
	active: true,
	announcements: [
		{ id: 0, text: "Kostenloser Versand ab 70€" },
		{ id: 1, text: "Konstenlose Rücksendung" },
		{ id: 2, text: "Summer sale! Bis zu 60% Rabatt" },
	],
};

function AnnouncementBanner() {
	const [currentAnn, setCurrentAnn] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			if (currentAnn >= content.announcements.length - 1) setCurrentAnn(0);
			else setCurrentAnn(currentAnn + 1);
		}, 5000);

		return () => clearInterval(interval);
	}, [currentAnn]);

	if (!content.active) return "";
	// return <div className={s.container}>{content.announcements[currentAnn]}</div>;
	return (
		<div className={s.container}>
			{content.announcements.map((ann) => (
				<div
					key={ann.id}
					className={currentAnn === ann.id ? s.visible : s.hidden}>
					{ann.text}
				</div>
			))}
		</div>
	);
}

export default AnnouncementBanner;
