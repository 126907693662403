function PintrestLogo() {
	return (
		<svg width={35} height={35} fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M17.514 0C7.842 0 0 7.829 0 17.486c0 7.411 4.613 13.743 11.129 16.29-.16-1.38-.289-3.51.057-5.022.317-1.367 2.047-8.692 2.047-8.692s-.519-1.05-.519-2.59c0-2.433 1.413-4.246 3.171-4.246 1.5 0 2.22 1.122 2.22 2.46 0 1.497-.951 3.742-1.456 5.83-.418 1.74.88 3.165 2.595 3.165 3.114 0 5.507-3.28 5.507-8.001 0-4.188-3.013-7.11-7.323-7.11-4.988 0-7.914 3.728-7.914 7.585 0 1.496.577 3.108 1.297 3.986a.52.52 0 0 1 .116.504c-.13.547-.433 1.741-.49 1.986-.072.316-.26.388-.591.23-2.192-1.022-3.561-4.202-3.561-6.778 0-5.512 4.007-10.578 11.575-10.578 6.07 0 10.797 4.317 10.797 10.103 0 6.03-3.805 10.88-9.081 10.88-1.773 0-3.445-.921-4.008-2.015l-1.095 4.159c-.39 1.525-1.456 3.425-2.177 4.59 1.643.505 3.373.778 5.19.778C27.157 35 35 27.171 35 17.514 35.029 7.83 27.187 0 17.514 0Z"
				fill="#000"
			/>
		</svg>
	);
}

export default PintrestLogo;
