import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import Button from "../../../reusables/Button";
import s from "./CategoryItem.module.css";

function CategoryItem(props) {
	useTranslation();
	return (
		<div className={s.container}>
			<img
				className={s.image}
				src={props.imglink}
				alt="Alternative text missing"
			/>
			<div className={s.info}>
				<h1 className={s.title}>{props.title}</h1>
				<Button>
					<Link to="all">
						<Trans i18nKey="slider-btn">Jetzt Shoppen</Trans>
					</Link>
				</Button>
			</div>
		</div>
	);
}

export default CategoryItem;
