import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import "./index.css";
import Liujun from "./Liujun";

import Spinner from "./reusables/Spinner";

import "./i18n";

ReactDOM.render(
	<React.StrictMode>
		<Suspense fallback={<Spinner />}>
			<BrowserRouter>
				<Liujun />
			</BrowserRouter>
		</Suspense>
	</React.StrictMode>,
	document.getElementById("root")
);
