// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes Spinner_spin__9tGHk {\n\t0% {\n\t\ttransform: rotate(0deg);\n\t}\n\t100% {\n\t\ttransform: rotate(360deg);\n\t}\n}\n\n.Spinner_container__\\+Yc7m {\n\tmargin-top: 5em;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n}\n\n.Spinner_spinner__XN9AO {\n\twidth: 75px;\n\theight: 75px;\n\tborder: 10px solid var(--accent-lightest);\n\tborder-top: 10px solid var(--accent-base);\n\tborder-radius: 50%;\n\tanimation: Spinner_spin__9tGHk 1s linear infinite;\n}\n", "",{"version":3,"sources":["webpack://./src/reusables/Spinner.module.css"],"names":[],"mappings":"AAAA;CACC;EACC,uBAAuB;CACxB;CACA;EACC,yBAAyB;CAC1B;AACD;;AAEA;CACC,eAAe;CACf,aAAa;CACb,mBAAmB;CACnB,uBAAuB;AACxB;;AAEA;CACC,WAAW;CACX,YAAY;CACZ,yCAAyC;CACzC,yCAAyC;CACzC,kBAAkB;CAClB,iDAAkC;AACnC","sourcesContent":["@keyframes spin {\n\t0% {\n\t\ttransform: rotate(0deg);\n\t}\n\t100% {\n\t\ttransform: rotate(360deg);\n\t}\n}\n\n.container {\n\tmargin-top: 5em;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n}\n\n.spinner {\n\twidth: 75px;\n\theight: 75px;\n\tborder: 10px solid var(--accent-lightest);\n\tborder-top: 10px solid var(--accent-base);\n\tborder-radius: 50%;\n\tanimation: spin 1s linear infinite;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Spinner_container__+Yc7m",
	"spinner": "Spinner_spinner__XN9AO",
	"spin": "Spinner_spin__9tGHk"
};
export default ___CSS_LOADER_EXPORT___;
