// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Home_headers__Tqx\\+K {\n\ttext-align: center;\n\tmargin-top: 40px;\n\tmargin-bottom: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Home.module.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,gBAAgB;CAChB,mBAAmB;AACpB","sourcesContent":[".headers {\n\ttext-align: center;\n\tmargin-top: 40px;\n\tmargin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headers": "Home_headers__Tqx+K"
};
export default ___CSS_LOADER_EXPORT___;
