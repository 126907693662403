// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AGB_container__7xMnz {\n\tmargin: 2em auto;\n\tmax-width: 40em;\n\tline-height: 1.5;\n\tfont-size: 15px;\n\tpadding: 0 0.5em;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/AGB.module.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,eAAe;CACf,gBAAgB;CAChB,eAAe;CACf,gBAAgB;AACjB","sourcesContent":[".container {\n\tmargin: 2em auto;\n\tmax-width: 40em;\n\tline-height: 1.5;\n\tfont-size: 15px;\n\tpadding: 0 0.5em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AGB_container__7xMnz"
};
export default ___CSS_LOADER_EXPORT___;
