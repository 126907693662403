function YoutubeLogo() {
	return (
		<svg width={35} height={35} fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.5 35C27.165 35 35 27.165 35 17.5S27.165 0 17.5 0 0 7.835 0 17.5 7.835 35 17.5 35Zm10.061-22.658a2.657 2.657 0 0 0-1.856-1.894C24.067 10 17.5 10 17.5 10s-6.567 0-8.205.448a2.657 2.657 0 0 0-1.856 1.894C7 14.013 7 17.5 7 17.5s0 3.487.439 5.158a2.657 2.657 0 0 0 1.856 1.894C10.933 25 17.5 25 17.5 25s6.567 0 8.205-.448a2.657 2.657 0 0 0 1.856-1.894C28 20.987 28 17.5 28 17.5s0-3.487-.439-5.158ZM15.352 20.52l5.489-3.122-5.489-3.123v6.245Z"
				fill="#000"
			/>
		</svg>
	);
}

export default YoutubeLogo;
