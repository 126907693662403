import { Routes, Route } from "react-router-dom";
import { useEffect, useState, createContext } from "react";

import Layout from "./Layout";

import AGB from "./pages/AGB";
import Home from "./pages/Home";
import SingleProduct from "./pages/SingleProduct";
import ProductList from "./pages/ProductList";
import ProductEditor from "./pages/ProductEditor";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Bag from "./pages/Bag";
import Profile from "./pages/Profile";

const UserContext = createContext(null);

function Liujun() {
  const [user, setUser] = useState({login: false, loading: true});
  user.set = setUser;
  useEffect(() => {
    fetch("/api/hello")
      .then((res) => res.json())
      .then((data) => {
        setUser({login: data.login, loading: false});
      });
  }, []);

	return (
    <UserContext.Provider value={user}>
      <Layout>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="agb" exact element={<AGB />} />
          <Route path="product/:id/" exact element={<SingleProduct />} />
          <Route path="all" exact element={<ProductList />} />
          <Route path="new" exact element={<ProductEditor />} />
          <Route path="register" exact element={<Register />} />
          <Route path="login" exact element={<Login />} />
          <Route path="profile" exact element={<Profile />} />
          <Route path="bag" exact element={<Bag />} />
          <Route path="profile" exact element={<Profile />} />
        </Routes>
      </Layout>
    </UserContext.Provider>
	);
}

export {Liujun as default, UserContext};
