import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import ProductCard from "../reusables/ProductCard";
import Spinner from "../reusables/Spinner";
import s from "./ProductList.module.css";

function searchData() {
  return {
    category: null,
    color: null,
    size: null,
    order: {
      by: "rating",
      dir: -1
    }
  };
}

function ProductList() {
	useTranslation();
	const [products, setProducts] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
    let opts = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(searchData())
    };
		fetch("/api/items/search", opts)
			.then((res) => res.json())
			.then((data) => {
				setProducts(data);
				setLoading(false);
			});
	}, []);

	if (loading) return <Spinner />;

	return (
		<div className={s.container}>
			<h1 className={s.title}>
				<Trans i18nKey="list.header">Produkte</Trans>
			</h1>
			<div className={s.filtersection}>
				<div className={s.filter}>
					<span className={s.filtertext}>
						<Trans i18nKey="list.filter">Produkte filtern</Trans>
					</span>
					<select className={s.menu} defaultValue="null">
						<option value="null" disabled>
							Farbe wählen
						</option>
						<option>Weiß</option>
						<option>Schwarz</option>
						<option>Rot</option>
						<option>Dunkelblau</option>
						<option>Beige</option>
					</select>
					<select className={s.menu} defaultValue="null">
						<option value="null" disabled>
							Größe wählen
						</option>
						<option>XS</option>
						<option>S</option>
						<option>M</option>
						<option>L</option>
						<option>XL</option>
					</select>
				</div>
				<div className={s.filter}>
					<span className={s.filtertext}>Produkte sortieren</span>
					<select className={s.menu} defaultValue="rating">
						<option value="rating">Beliebteste Produkte</option>
						<option value="priceup">Preis (aufsteigend)</option>
						<option value="pricedown">Preis (absteigend)</option>
						<option value="newest">Neueste Produkte zuerst</option>
					</select>
				</div>
			</div>
			<div className={s.products}>
				{products.map((product) => (
					<ProductCard
						key={product.id}
						id={product.id}
						img={product.image}
						price={product.price}
					/>
				))}
			</div>
		</div>
	);
}

export default ProductList;
