function MasterCardIcon() {
	return (
		<svg
			id="Payment_Icons"
			data-name="Payment Icons"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 38 38">
			<defs>
				<style>{".cls-1{fill:#fff}"}</style>
			</defs>
			<title>{"mastercard-fill"}</title>
			<rect y={7} width={38} height={24} rx={5} />
			<path
				className="cls-1"
				d="M18.11 15.08a4.75 4.75 0 1 0 0 7.84 5.93 5.93 0 0 1 0-7.84Z"
			/>
			<circle className="cls-1" cx={22.56} cy={19} r={4.75} />
		</svg>
	);
}

export default MasterCardIcon;
