import { Trans, useTranslation } from "react-i18next";
import s from "./Bag.module.css";
import BagProduct from "./bag/BagProduct";
import {useEffect, useState} from "react";

let prods = [
	{
		name: "Tasche",
		color: "Weiß",
		price: 30,
		img: "./pix/1.jpg",
	},
	{
		name: "Tasche",
		color: "Schwarz",
		price: 40,
		img: "./pix/2.jpg",
	},
	{
		name: "Tasche",
		color: "Gelb",
		price: 400,
		img: "./pix/3.jpg",
	},
];

let subtotal = 0;

prods.forEach((element) => {
	subtotal += element.price;
});

function Bag() {
  const [cart, setCart] = useState([]);
  
  useEffect(() => {
    fetch('/api/user/me/cart')
      .then((res) => res.json())
      .then((data) => {
        setCart(data);
        console.log(data);
      })
  }, []);

	useTranslation();

	return (
		<div className={s.container}>
			<h1 className={s.title}>
				<Trans i18nKey="bag.header">Deine Einkaufstasche</Trans>
			</h1>
			<div className={s.top}>
				<button className={s.top_btn}>
					<Trans i18nKey="bag.cont">Weitershoppen</Trans>
				</button>
				<button className={s.top_btn}>
					<Trans i18nKey="bag.checkout">Zur Kasse</Trans>
				</button>
			</div>
			<div className={s.bottom}>
				<div className={s.bottom_left}>
					{cart.map((product) => (
						<div className={s.single_prod}>
							<BagProduct
								// key={product.id}
								name={product.name}
								size={product.size}
								color={product.color}
								price={product.price}
								img={product.images}
                number={product.quantity}
							/>
						</div>
					))}
				</div>
				<div className={s.bottom_right}>
					<h1 className={s.sum_title}>
						<Trans i18nKey="bag.ordersum">Zusammenfassung</Trans>
					</h1>
					<div className={s.sum_section}>
						<span>
							<Trans i18nKey="bag.subtot">
								Zwischensumme ({prods.length} Artikel)
							</Trans>
						</span>
						<span>{subtotal}€</span>
					</div>
					<div className={s.sum_section}>
						<span>
							<Trans i18nKey="bag.del">Versand</Trans>
						</span>
						<span>5€</span>
					</div>
					<div className={`${s.sum_section} ${s.final_sum_section}`}>
						<span>
							<Trans i18nKey="bag.tot">Gesamtbetrag</Trans>
						</span>
						<span>{subtotal + 5}€</span>
					</div>
					<button className={s.finalize_btn}>
						<Trans i18nKey="bag.btn">Bestellen</Trans>
					</button>
				</div>
			</div>
		</div>
	);
}

export default Bag;
