import { Trans, useTranslation } from "react-i18next";
import { useContext, setContext } from "react";
import { Link } from "react-router-dom";
import SearchIcon from "../icons/SearchIcon";
import ShoppingBagIcon from "../icons/ShoppingBagIcon";

import { UserContext } from "../Liujun";

import s from "./Navbar.module.css";

function logout(event, context) {
  fetch("/api/logout", {method: 'POST'})
    .then((res) => {
      if(res.status===204) {
        console.log("logged out");
        context.set({login: false, loading: false});
      }
      else {
        event.preventDefault();
        console.log("Logout failed");
      }
    });
}

function userElements(context) {
  if(context.loading)
    return;
  if(context.login)
    return (<>
      <div className={s.menu_item}>
        <Link to="/profile">
          <Trans i18nKey="navbar.profile">Profil</Trans>
        </Link>
      </div>
      <div className={s.menu_item}>
        <Link to="/" onClick={(e) => {logout(e, context)}}>
          <Trans i18nKey="navbar.logout">Logout</Trans>
        </Link>
      </div>
    </>);
  return (<>
    <div className={s.menu_item}>
      <Link to="/register">
        <Trans i18nKey="navbar.reg">Registrieren</Trans>
      </Link>
    </div>
    <div className={s.menu_item}>
      <Link to="/login">
        <Trans i18nKey="navbar.login">Einloggen</Trans>
      </Link>
    </div>
  </>)
}

function Navbar() {
  const { i18n } = useTranslation();

  const context = useContext(UserContext);

	function changeLang(lng) {
		i18n.changeLanguage(lng);
	}

	return (
		<div className={s.navbar}>
			<div className={s.wrapper}>
				<div className={s.left}>
					<select
						onChange={(e) => changeLang(e.target.value)}
						className={s.language}>
						<option value="de">DE</option>
						<option value="en">EN</option>
					</select>
					<div className={s.search_container}>
						<input className={s.input} placeholder="Suchen" />
						<div className={s.magnifier}>
							<SearchIcon />
						</div>
					</div>
				</div>
				<div className={s.center}>
					<h1 className={s.logo}>
						<Link to="/">Liujun</Link>
					</h1>
				</div>
				<div className={s.right}>
          {userElements(context)}
					<div className={s.menu_item}>
						<div className={s.shoppingbag}>
							<Link to="/bag">
								<ShoppingBagIcon item_count={0} />
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Navbar;
