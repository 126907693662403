import LocationPinIcon from "../icons/LocationPinIcon";
import PhoneIcon from "../icons/PhoneIcon";
import ClockIcon from "../icons/ClockIcon";
import EnvelopeIcon from "../icons/EnvelopeIcon";
import PaperplaneIcon from "../icons/PaperplaneIcon";

import FacebookLogo from "../icons/socials/FacebookLogo";
import InstagramLogo from "../icons/socials/InstagramLogo";
import YoutubeLogo from "../icons/socials/YoutubeLogo";
import TwitterLogo from "../icons/socials/TwitterLogo";
import PintrestLogo from "../icons/socials/PintrestLogo";

import VisaIcon from "../icons/payment/VisaIcon";
import MasterCardIcon from "../icons/payment/MasterCardIcon";
import PayPalIcon from "../icons/payment/PayPalIcon";

import s from "./Footer.module.css";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

function Footer() {
	useTranslation();
	return (
		<div className={s.container}>
			<div className={s.section}>
				<div className={s.left}>
					<h4 className={s.links_header}>
						<Trans i18nKey="footer.contact">Kontakt</Trans>
					</h4>
					<div className={s.contact}>
						<div className={s.contact_icon}>
							<LocationPinIcon />
						</div>
						randomstreet 69 | randomcity 12345
					</div>
					<div className={s.contact}>
						<div className={s.contact_icon}>
							<PhoneIcon />
						</div>
						+1 234 567890
					</div>
					<div className={s.contact}>
						<div className={s.contact_icon}>
							<ClockIcon />
						</div>
						07:00 - 16:00 Mo. - Fr.
					</div>
					<div className={s.contact}>
						<div className={s.contact_icon}>
							<EnvelopeIcon />
						</div>
						random@email.com
					</div>
				</div>
				<div className={s.center}>
					<h4 className={s.links_header}>Service</h4>
					<ul className={s.links_list}>
						<li className={s.link}>
							<Trans i18nKey="footer.ser.faq">Häufig gestellte Fragen</Trans>
						</li>
						<li className={s.link}>
							<Trans i18nKey="footer.ser.del">Lieferung</Trans>
						</li>
						<li className={s.link}>
							<Trans i18nKey="footer.ser.ret">Rücksendung</Trans>
						</li>
						<li className={s.link}>
							<Trans i18nKey="footer.ser.pay">Zahlung</Trans>
						</li>
					</ul>
				</div>
				<div className={s.right}>
					<h4 className={s.links_header}>
						<Trans i18nKey="footer.recht-header">Rechtliches</Trans>
					</h4>
					<ul className={s.links_list}>
						<li className={s.link}>
							<Link to="/agb">
								<Trans i18nKey="footer.recht.agb">AGB</Trans>
							</Link>
						</li>
						<li className={s.link}>
							<Trans i18nKey="footer.recht.cap">Cookies und Datenschutz</Trans>
						</li>
						<li className={s.link}>
							<Trans i18nKey="footer.recht.imp">Impressum</Trans>
						</li>
					</ul>
				</div>
			</div>
			<div className={s.section}>
				<div className={s.left}>
					<h4 className={s.menu_header}>
						<Trans i18nKey="footer.soc-header">Folgt uns</Trans>
					</h4>
					<div className={s.socials}>
						<div className={s.social_link}>
							<FacebookLogo />
						</div>
						<div className={s.social_link}>
							<InstagramLogo />
						</div>
						<div className={s.social_link}>
							<YoutubeLogo />
						</div>
						<div className={s.social_link}>
							<TwitterLogo />
						</div>
						<div className={s.social_link}>
							<PintrestLogo />
						</div>
					</div>
				</div>
				<div className={s.center}>
					<h4 className={s.links_header}>
						<Trans i18nKey="footer.pay-header">
							Akzeptierte Zahlungsmethoden
						</Trans>
					</h4>
					<div className={s.payments}>
						<div className={s.payment_link}>
							<VisaIcon />
						</div>
						<div className={s.payment_link}>
							<MasterCardIcon />
						</div>
						<div className={s.payment_link}>
							<PayPalIcon />
						</div>
					</div>
				</div>
				<div className={s.right}>
					<h4 className={s.links_header}>
						<Trans i18nKey="footer.nl-header">
							Abonniert unseren Newsletter
						</Trans>
					</h4>
					<div className={s.newsletter}>
						<input className={s.email_field} placeholder="email@beispiel.de" />
						<button className={s.btn}>
							<PaperplaneIcon />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Footer;
