// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProductList_title__Ns3fQ {\n\tmargin: 20px;\n}\n\n.ProductList_filtersection__FXIYS {\n\tdisplay: flex;\n\tjustify-content: space-between;\n}\n\n.ProductList_filter__EBeE2 {\n\tmargin: 20px;\n}\n\n.ProductList_filtertext__r04mo {\n\tfont-size: 20px;\n\tfont-weight: 600;\n\tmargin-right: 20px;\n}\n\n.ProductList_menu__vuL7M {\n\tpadding: 10px;\n\tmargin-right: 20px;\n\tfont-family: inherit;\n}\n\n.ProductList_products__qPsX1 {\n\tpadding: 10px;\n\tdisplay: flex;\n\tflex-wrap: wrap;\n\tjustify-content: space-between;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/ProductList.module.css"],"names":[],"mappings":"AAAA;CACC,YAAY;AACb;;AAEA;CACC,aAAa;CACb,8BAA8B;AAC/B;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,eAAe;CACf,gBAAgB;CAChB,kBAAkB;AACnB;;AAEA;CACC,aAAa;CACb,kBAAkB;CAClB,oBAAoB;AACrB;;AAEA;CACC,aAAa;CACb,aAAa;CACb,eAAe;CACf,8BAA8B;AAC/B","sourcesContent":[".title {\n\tmargin: 20px;\n}\n\n.filtersection {\n\tdisplay: flex;\n\tjustify-content: space-between;\n}\n\n.filter {\n\tmargin: 20px;\n}\n\n.filtertext {\n\tfont-size: 20px;\n\tfont-weight: 600;\n\tmargin-right: 20px;\n}\n\n.menu {\n\tpadding: 10px;\n\tmargin-right: 20px;\n\tfont-family: inherit;\n}\n\n.products {\n\tpadding: 10px;\n\tdisplay: flex;\n\tflex-wrap: wrap;\n\tjustify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "ProductList_title__Ns3fQ",
	"filtersection": "ProductList_filtersection__FXIYS",
	"filter": "ProductList_filter__EBeE2",
	"filtertext": "ProductList_filtertext__r04mo",
	"menu": "ProductList_menu__vuL7M",
	"products": "ProductList_products__qPsX1"
};
export default ___CSS_LOADER_EXPORT___;
