function TwitterLogo() {
	return (
		<svg width={35} height={35} fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.5 35C27.165 35 35 27.165 35 17.5S27.165 0 17.5 0 0 7.835 0 17.5 7.835 35 17.5 35Zm8.712-21.38c0 5.93-4.515 12.77-12.77 12.77v-.004a12.706 12.706 0 0 1-6.88-2.013 9.005 9.005 0 0 0 6.642-1.86 4.492 4.492 0 0 1-4.192-3.117c.672.13 1.365.103 2.026-.077a4.49 4.49 0 0 1-3.6-4.4v-.056c.624.348 1.322.54 2.037.562a4.492 4.492 0 0 1-1.39-5.992 12.743 12.743 0 0 0 9.25 4.69 4.492 4.492 0 0 1 7.648-4.094 8.994 8.994 0 0 0 2.85-1.09 4.504 4.504 0 0 1-1.973 2.483 8.958 8.958 0 0 0 2.578-.706 9.133 9.133 0 0 1-2.24 2.323c.014.193.014.386.014.58Z"
				fill="#000"
			/>
		</svg>
	);
}

export default TwitterLogo;
