import { Link } from "react-router-dom";
import HeartIcon from "../icons/HeartIcon";
import InfoIcon from "../icons/InfoIcon";
import ShoppingBagIcon from "../icons/ShoppingBagIcon";

import s from "./ProductCard.module.css";

function addToCart(item) {
  fetch("/api/user/me/cart/", {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({item: item, quantity: 1})
  });
}

function ProductCard(props) {
	return (
		<div className={s.container}>
			<img className={s.image} src={props.img} alt="Alternative text missing" />
			<div className={s.buttons}>
				<div className={s.icon}>
					<Link to={"/product/" + props.id}>
						<InfoIcon />
					</Link>
				</div>
				<div className={s.icon} onClick={() => {addToCart(props.id)}}>
					<ShoppingBagIcon add={true} />
				</div>
				<div className={s.icon}>
					<HeartIcon />
				</div>
			</div>
			<span className={s.price}>{props.price}€</span>
		</div>
	);
}

export default ProductCard;
